<template>
 <v-row class="mx-1 mt-1" color="tapiz">


  <v-col cols="5" xs="8" sm="5"  md="5"  lg="6" xl="6" class="mt-2">

<v-icon slot="icon" color="red" size="25" class="mx-2">flag</v-icon>
<span class="text-subtitle-2 pt-3 mt-3">Vista de jornadas de conductor por pais</span>

<v-chip class="ma-2" color="primary"  label > 
   <v-icon left>work</v-icon>
   conductores  
{{ totalconductores -1 }} 
</v-chip>



</v-col>

    <v-col cols="6" xs="6" sm="6" md="6" lg="6" xl="6" class="float-right text-lg-right text-md-right text-right">
   
   <v-flex class="d-inline-flex pb-0 pt-0">


    <v-select v-model="id_pais_activo" :items="PaisesArray" small  style="width:150px;height:30px;padding-top:0px;" @change="ListarFlotas()"
        label="Pais">
      </v-select>



    <v-tooltip bottom class="d-inline-flex">
          <template v-slot:activator="{ on, attrs }">
        <v-btn class="mx-2 mt-2" icon v-bind="attrs"  v-on="on" small color="primary" @click="dialogInicio=true">
            <v-icon dark>
            today
            </v-icon>
          </v-btn>

          </template>
      <span>Cambio fechas</span>
      </v-tooltip>


    <v-btn color="info" text outlined  @click="CambiarVista()" small class="mt-1"><v-icon>account_circle</v-icon>Conductores</v-btn>


<v-tooltip bottom class="float-right">
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon  v-bind="attrs"  v-on="on" dark color="indigo" @click="dialogExit=true"> 
            <v-icon dark>logout</v-icon>
          </v-btn>
        </template>
        <span>Salir de aplicacion</span>
    </v-tooltip>

   </v-flex>
 </v-col>

 <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6" class="ml-5">


<v-card>
       <v-card-title>
         <span> Consolidado tiempo extra jornada</span>
       </v-card-title>

       <v-card-text>

         <div id="chart" class="pt-0 pb-0 mt-0" style="margin-top:-23px">
           <apexchart type="bar"  height="320" :options="chartOptionsExtra" style="margin-top:-23px" :series="seriesExtra"></apexchart>
           </div>

       </v-card-text>

 </v-card>
</v-col>


 <v-col cols="12" xs="12" sm="12" md="5" lg="5" xl="5" class="ml-5">



  <v-card>
        <v-card-title>
          <span> Horas trabajadas</span>
        </v-card-title>

        <v-card-text>
          <v-simple-table dense>
    <template v-slot:default>
      <thead>
        <tr>
          <th class="text-left">
            Conductor
          </th>
          <th class="text-left">
            Horas totales
          </th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="item in arrayResumen"
          :key="item._id"
        >
          <td>{{ item.nombre_conductor }}</td>
          <td>{{ parseFloat(item.horas_total).toFixed(2) }}</td>
        </tr>
      </tbody>
    </template>
  </v-simple-table>


        </v-card-text>

  </v-card>

</v-col>











    <v-card class="ml-5">
        <v-card-title>
           <span> Historial horas jornada</span>
    
            <v-divider  class="mx-4" inset vertical></v-divider>
    
               <v-flex md2 lg2 mx-4 pt-5>
                
                       <v-select v-model="id_conductor_vista_jornada" :items="SelectConductor" small  outlined @change="FiltrarJornadasCond()"   label="Conductor" class="mt-2">
                      </v-select> 

               </v-flex>

               <v-btn dark color="success" @click="dialogInicio=true" class="mx-1">
                  <v-icon>date_range</v-icon>
                </v-btn>


                <vue-excel-xlsx  class="mx-1" v-show="ArrayJornadas.length >0"
                  :data="ArrayJornadas"
                  :columns="column_excel"
                  :filename="'Historial_jornadas'"
                  :sheetname="'hoja1'">
                  <v-btn color="info">
                      <v-icon dark>get_app</v-icon>
                  </v-btn>
                  
                  </vue-excel-xlsx>

                <v-spacer></v-spacer>
        
        <v-text-field
          v-model="search"
          append-icon="search"
          label="buscar"
          single-line
          hide-details
        ></v-text-field>

        
    </v-card-title>

    
  <v-data-table
    :headers="headers"
    :items="ArrayJornadas"
     sort-by="calories"
    :search="search"
    class="elevation-1"
  >
  
  
    <template v-slot:no-data>
      <v-btn  color="primary" @click="initialize">
        Reset
      </v-btn>
    </template>

     <template v-slot:[`item.fecha`]="{ item }"> 
       <span>{{ConvertirFecha(item.fecha)}} </span>
     </template>


     

  </v-data-table>


  <v-dialog v-model="dialogInicio" persistent max-width="280">
      <v-card>
         <v-toolbar flat color="fondo1" class="text-center">
        <v-toolbar-title class="white--text  text-center"><strong>Jornadas por fechas</strong></v-toolbar-title>
      </v-toolbar>

        <v-card-text>



           <v-flex>
      
                    <v-menu
                      ref="menuFecha1"
                      v-model="menuFecha1"
                      :close-on-content-click="false"
                      :return-value.sync="date"
                      transition="scale-transition"
                  
                      offset-y
                      min-width="290px"
                    >
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                              v-model="fecha_inicio"
                              label="Fecha inicio"
                              prepend-icon="event"
                              readonly
                              v-bind="attrs"
                              v-on="on"
                            ></v-text-field>
                          </template>
                          <v-date-picker v-model="fecha_inicio" locale="es"
                          no-title  @input="menuFecha1 = false" scrollable>
                          </v-date-picker>
                    </v-menu>
              </v-flex>

          <v-flex>
                 <v-menu
                  ref="menuFecha2"
                  v-model="menuFecha2"
                  :close-on-content-click="false"
                  :return-value.sync="date"
                  transition="scale-transition"
              
                  offset-y
                  min-width="290px"
                >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="fecha_fin"
                          label="Fecha fin"
                          prepend-icon="event"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker v-model="fecha_fin" locale="es"
                       no-title  @input="menuFecha2 = false" scrollable>
                      </v-date-picker>
                </v-menu>

              </v-flex>

           
                <v-flex justify-center d-flex>
                  
                      <v-btn color="primary" dark @click="listar()" style="margin-right:10px;">Generar</v-btn>
                    <v-btn  @click="dialogInicio=false" style="margin-right:10px;">Cancelar</v-btn>

               </v-flex>
   
        </v-card-text>
       
      </v-card>
</v-dialog>


   <v-dialog v-model="dialogExit"  hide-overlay
                     persistent
                     width="250">
                        <v-card>
                          <v-card-title>Salir de aplicacion</v-card-title>
                          <v-card-text>Deseas salir?</v-card-text>
                          <v-card-actions class="justify-space-around">
                            <v-btn @click="dialogExit=false" text>Cancelar</v-btn>
                          <v-btn @click="cerrar()" dark color="indigo">Salir</v-btn></v-card-actions>
                        </v-card>
  </v-dialog>




</v-card>

</v-row>


</template>


<script>


import axios from 'axios';
import { mapMutations, mapState, mapActions } from "vuex";


export default {
    data: () => ({
        search:'',
      PaisesArray:[],
      id_pais_activo:'',
      dialog: false,
      dialogExit:false,

      headers: [
                { text: 'Conductor', value: 'id_conductor.nombre_conductor' },
                { text: 'Tipo jornada', value: 'id_tipojornada.nombre_jornada' },
                { text: 'Fecha', value: 'fecha' },

                { text: 'Inicio', value: 'inicio' },
                { text: 'Fin', value: 'fin' },

                { text: 'Tiempo total', value: 'tiempo_total' },
                { text: 'horas total', value: 'horas_total' },


                { text: 'Inicio po', value: 'inicio_po' },
                { text: 'Fin po', value: 'fin_po' },
                { text: 'Tiempo_extra', value: 'tiempo_extra' },
                { text: 'Horas extra', value: 'horas_extra' },

                { text: 'Extra diurnas', value: 'horas_extra_diurnas' },
                { text: 'Extra nocturnas', value: 'horas_extra_nocturnas' }


            
                ],


                column_excel:[ 
                              {label: "Conductor",  field: "nombre_conductor"},
                              {label: "Tipo jornada", field: "tipojornada"},
                              {label: "Fecha", field: "fecha_"},
                              {label: "Inicio", field: "inicio"},
                              {label: "Fin",  field: "fin"},

                              {label: "Tiempo total", field: "tiempo_total"},
                              {label: "Horas total", field: "horas_total"},
                              {label: "Inicio programado", field: "inicio_po"},
                              {label: "Fin programado", field: "fin_po"},
                              {label: "Tiempo extra",  field: "tiempo_extra"},
                              {label: "Horas extra",  field: "horas_extra"}
                ],


        ArrayJornadas: [],


        menu: false,
        dialogInicio:false,

        menuFecha1:false,
        fecha_inicio:new Date().toISOString().substr(0, 10),
        menuFecha2:false,
        fecha_fin:new Date().toISOString().substr(0, 10),
        date: new Date().toISOString().substr(0, 10),


        seriesExtra:[{
                          name: 'Hrs Extra',
                          data: []
                      }],

                chartOptionsExtra:{
                                        chart: {
                                            type: 'bar',
                                            height:260
                                            },
                                            plotOptions: {
                                            bar: {
                                                horizontal: true,
                                                columnWidth: '55%',
                                                endingShape: 'rounded'
                                            },
                                            },
                                            dataLabels: {
                                            enabled: false
                                            },
                                            stroke: {
                                            show: true,
                                            width: 2,
                                            colors: ['transparent']
                                            },
                                            xaxis: {
                                            categories: ['datos'],
                                            },
                                            yaxis: {
                                            title: {
                                                text: ''
                                            }
                                            },
                                            fill: {
                                            opacity: 1
                                            },
                                            tooltip: {
                                            y: {
                                                formatter: function (val) {
                                                return " " + val + ""
                                                }
                                            }
                                            }
                                        },


                                        totalconductores:0,

                                        arrayResumen: [],


                                        id_conductor_vista_jornada:1,
                                         SelectConductor:[{text:'-Todos-', value:1}],
                                        arrayJornadasAll:[],


                                     

     
}),

computed: {
      ...mapState(['usuario', 'token']),
},

watch: {
 
},

created () {
  this.initialize()
},

methods: {

    ...mapMutations(['mostrarLoading','ocultarLoading']),

    initialize () {
      this.ArrayJornadas = [];
      this.listarPaises (); 
    },

    listarPaises () {

    let config={headers:{token:this.token}};
    this.mostrarLoading({titulo:'Accediendo a datos'});

    let me=this;

    let RespuestaPaises=[];

    axios.get('paises',  config
    ).then(function(response){
        console.log(response);
        RespuestaPaises=response.data;

        RespuestaPaises.map(function(x){
            me.PaisesArray.push({text:x.nombre_pais, value:x._id})
          });

        me.id_pais_activo=me.PaisesArray[0].value;

        me.ocultarLoading();
        
    }).catch(function(error){
      console.log(error);
    });
    },



    listar(){

    this.ArrayJornadas=[];
    this.arrayJornadasAll=[];


    this.mostrarLoading({titulo:'Accediendo a datos'});
    let config={headers:{token:this.token}};
    let me=this;
    axios.post('jornadas-pais',
          {   
            'desde':this.fecha_inicio,
            'hasta':this.fecha_fin,
            'id_pais':this.id_pais_activo
          },
        config
        ).then(function(response){
          // console.log(response);



          let respuesta=response.data;

          respuesta.map(function(x){
            x.fecha_=me.ConvertirFecha(x.fecha);
            x.nombre_conductor=x.id_conductor.nombre_conductor;
            x.tipojornada=x.id_tipojornada.nombre_jornada;
          });
        
            
            me.ArrayJornadas=respuesta;
            me.arrayJornadasAll=respuesta;

            me.ResumenExtras();
            me.listarConductores();
      
            me.ocultarLoading();
        }).catch(function(error){ console.log(error); });

    },

      ResumenExtras(){

        let labels=[];

        let valores_array=[];

        this.arrayResumen=[];


      this.mostrarLoading({titulo:'Accediendo a datos'});
      let config={headers:{token:this.token}};
      let me=this;
      axios.post('jornadas-paisextra',
            {   
            'desde':this.fecha_inicio,
            'hasta':this.fecha_fin,
            'id_pais':this.id_pais_activo
          },
        config
        ).then(function(response){

            console.log(response.data);

            let respuesta=response.data;

            me.arrayResumen=respuesta;

            respuesta.map(function(x){

              labels.push(x.nombre_conductor);
              valores_array.push(parseFloat(x.horas_extra).toFixed(2));
            });



            me.seriesExtra=[{
                          name: 'Horas extras',
                          data: valores_array
                          }];


                          me.chartOptionsExtra={
                                                    chart: {
                                                        type: 'bar',
                                                        height:300
                                                        },
                                                        
                                                        plotOptions: {
                                                        bar: {
                                                            horizontal: false,
                                                            columnWidth: '55%',
                                                            endingShape: 'rounded'
                                                        },
                                                        },
                                                        dataLabels: {
                                                        enabled: true
                                                        },
                                                        stroke: {
                                                        show: true,
                                                        width: 2,
                                                        colors: ['transparent']
                                                        },
                                                        xaxis: {
                                                        categories: labels,
                                                        },
                                                        yaxis: {
                                                        title: {
                                                            text: 'Horas extras'
                                                        }
                                                        },
                                                        fill: {
                                                        opacity: 1
                                                        },
                                                        tooltip: {
                                                        y: {
                                                            formatter: function (val) {
                                                            return " " + val + ""
                                                            }
                                                        }
                                                        }
                                                    };
          
            
          
            me.dialogInicio=false;
      
            me.ocultarLoading();
        }).catch(function(error){ console.log(error); });



      },


ConvertirFecha(fecha_parametro){
          let texto=new Date(fecha_parametro).toLocaleDateString();
          return(texto);

      },


      listarConductores(){ 


      let respuesta=[];

      this.totalconductores=0;


      this.SelectConductor=[{text:'-Todos-', value:1}];
      this.id_conductor_vista_jornada=1;


        let config={headers:{token:this.token}};
        this.mostrarLoading({titulo:'accediendo a datos'});
        let me=this;

        axios.get(`conductores/${this.id_pais_activo}`, config
        ).then(function(response){

          respuesta=response.data;

          respuesta.map(function(x){
            me.SelectConductor.push({text:x.nombre_conductor, value:x._id});
          });


          me.totalconductores=respuesta.length;


            me.ocultarLoading();
        }).catch(function(error){
          console.log(error);
      });

},


      ...mapActions(['salir']),

cerrar(){
    this.dialogExit=false;
    localStorage.removeItem('usuario');
    localStorage.removeItem('token');
    
    this.salir();
  },

  CambiarVista(){

  this.$router.push('/eventosconductor'); 

  },


  FiltrarJornadasCond(){

          let me=this;

          this.ArrayJornadas=[];

          if(this.id_conductor_vista_jornada==1){
                          this.ArrayJornadas=this.arrayJornadasAll;
            }else{
                   me.arrayJornadasAll.map(function(x){
                        if(x.id_conductor._id==me.id_conductor_vista_jornada){
                                    me.ArrayJornadas.push(x);
                        }
                    });
            }

  },



    },
  }
</script>
    